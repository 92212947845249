import React from 'react';
import { DefinedDateRange } from '../CourseForm/course-form-model';
import { Student } from '../../firebase/student.model';
import { DateRangeFormatter } from '../DateRangeFormatter/DateRangeFormatter';
import { isSameRange } from '../../utils/date-range-firebase-timestamp-comparator';
import { Checkbox, IconButton } from '@mui/material';
import { Delete } from '@mui/icons-material';

import styles from './AttendanceMatrixColumn.module.scss';

type Props = {
  range: DefinedDateRange;
  students: Student[];
  total: number;
  winning: boolean;
  onChange: (student: Student, date: DefinedDateRange) => void;
  onDeleteDateRange?: (date: DefinedDateRange) => void;
};

export const AttendanceMatrixColumn: React.FC<Props> = ({
  range,
  students,
  total,
  winning,
  onChange,
  onDeleteDateRange
}) => {
  const studentsAttendance = students.map(student => {
    return student.selectedDates.some(date => isSameRange(range, date));
  });
  return (
    <>
      <div className={`${styles['date-range']} ${winning && styles.winning}`}>
        <DateRangeFormatter range={range} />
        {onDeleteDateRange &&
          <IconButton color="secondary" onClick={() => onDeleteDateRange(range)} aria-label="Delete">
            <Delete />
          </IconButton>
        }
      </div>
      {studentsAttendance.map((attending, index) => (
        <div key={index} className={`${styles['attendance-cell']} ${winning && styles.winning}`}>
          <Checkbox
            checked={attending}
            onChange={() => onChange(students[index], range)}
          />
        </div>
      ))}
      <div className={`${styles.total} ${winning && styles.winning}`}>{total}</div>
    </>
  );
};
