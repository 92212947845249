import {
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import { Student } from './student.model';

export const studentConvertor: FirestoreDataConverter<Student> = {
  toFirestore(student: WithFieldValue<Student>): DocumentData {
    return {
      name: student.name,
      selectedDates: student.selectedDates,
      attendanceForm: student.attendanceForm,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Student {
    const student = snapshot.data(options)!;
    return {
      id: snapshot.id,
      name: student.name,
      selectedDates: student.selectedDates,
      attendanceForm: student.attendanceForm,
    };
  },
};
