import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth';

type Props = {
  children: React.ReactNode;
};

export function ProtectedRoute({ children }: Props) {
  const { isAdmin, loading } = useContext(AuthContext);
  const navigate = useNavigate();

  if (!isAdmin && !loading) {
    navigate('/', { replace: true });
    return <></>
  }

  return <>{children}</>;
}
