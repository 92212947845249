import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Course } from '../firebase/course.model';
import { doc } from 'firebase/firestore';
import { db } from '../db';
import { courseConverter } from '../firebase/course.converter';

export function useCourse(courseId: string) {
  return useDocumentData<Course>(
    doc(db, `/courses/${courseId}`).withConverter(courseConverter)
  );
}
