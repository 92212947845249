import { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import cs from 'date-fns/locale/cs';
import { DefinedDateRange } from '../CourseForm/course-form-model';
import { isAfter } from 'date-fns';
import 'react-day-picker/dist/style.css';

type Props = {
  onChange: (range: DefinedDateRange) => void;
};

export function DateRangePicker({ onChange }: Props) {
  const [first, setFirst] = useState<Date | null>(null);
  const [second, setSecond] = useState<Date | null>(null);
  const [enteredTo, setEnteredTo] = useState<Date | null>(null);

  function isSelectingFirstDay(day: Date) {
    const isRangeSelected = first && second;
    return !first || isRangeSelected;
  }

  function handleDayClick(day: Date) {
    if (first && second && day >= first && day <= second) {
      handleResetClick();
      return;
    }
    if (isSelectingFirstDay(day)) {
      setFirst(day);
      setSecond(null);
      setEnteredTo(null);
    } else {
      setSecond(day);
      setEnteredTo(day);
      updateFieldStatus(day);
    }
  }

  function updateFieldStatus(day: Date) {
    if (!first || !onChange) {
      return;
    }
    if (isAfter(day, first)) {
      onChange({
        from: first,
        to: day
      });
    } else {
      onChange({
        from: day,
        to: first
      });
    }
    handleResetClick();
  }

  function handleDayMouseEnter(day: Date) {
    if (!isSelectingFirstDay(day)) {
      setEnteredTo(day);
    }
  }

  function handleResetClick() {
    setFirst(null);
    setSecond(null);
    setEnteredTo(null);
  }

  const defaultSelected: DefinedDateRange | undefined = first && enteredTo ? {
    from: first,
    to: enteredTo
  } : undefined;

  return (
    <DayPicker
      mode="range"
      locale={cs}
      selected={defaultSelected}
      onDayClick={handleDayClick}
      onDayMouseEnter={handleDayMouseEnter}
    />
  );
}
