import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DateRangePicker } from '../DateRangePicker';
import { DefinedDateRange } from '../CourseForm/course-form-model';
import { DialogContent } from '@mui/material';

type Props = {
  open: boolean;
  onClose: (value?: DefinedDateRange) => void;
};

export const PickDateRangeDialog: React.FC<Props> = ({ onClose, open }) => {
  return (
    <Dialog onClose={() => onClose()} open={open}>
      <DialogTitle>Vyberte termín</DialogTitle>
      <DialogContent dividers>
        <DateRangePicker onChange={range => onClose(range)} />
      </DialogContent>
    </Dialog>
  );
};
