import { Course } from './course.model';
import { Omit } from '../utils/Omit';
import {
  CourseFormModel,
  DefinedDateRange,
} from './../components/CourseForm/course-form-model';
import {
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
  addDoc,
  collection,
} from 'firebase/firestore';
import { db } from '../db';
import { dateRangeToFirebaseRange } from '../utils/date-range-firebase-timestamp-convertor';
import { isSameRange } from '../utils/date-range-firebase-timestamp-comparator';

export async function createCourse(course: CourseFormModel) {
  const normalizedCourse: Omit<Course, 'id'> = {
    name: course.name,
    location: course.location,
    availableDates: course.availableDates.map((dateRange) =>
      dateRangeToFirebaseRange(dateRange)
    ),
    attendanceFormOptions: course.attendanceFormOptions,
  };

  const courseRef = await addDoc(collection(db, `courses`), normalizedCourse);

  await Promise.all(
    course.students.map((student) =>
      addDoc(collection(db, `courses/${courseRef.id}/students`), {
        name: student,
        selectedDates: [],
      })
    )
  );
  return courseRef.id;
}

export function addDateRange(course: Course, range: DefinedDateRange) {
  return updateDoc(doc(db, `/courses/${course.id}`), {
    availableDates: [...course.availableDates, dateRangeToFirebaseRange(range)],
  });
}

export function deleteDateRange(
  course: Course,
  deletedRange: DefinedDateRange
) {
  return updateDoc(doc(db, `/courses/${course.id}`), {
    availableDates: course.availableDates.filter(
      (range) => !isSameRange(deletedRange, range)
    ),
  });
}

export function updateCourse(courseId: Course['id'], course: Partial<Course>) {
  return updateDoc(doc(db, `/courses/${courseId}`), {
    ...course,
  });
}

export async function deleteCourse(course: Course) {
  const students = await getDocs(
    collection(db, `/courses/${course.id}/students`)
  );
  const deletePromises = students.docs.map((student) =>
    deleteDoc(doc(db, `/courses/${course.id}/students/${student.id}`))
  );
  await Promise.all(deletePromises);

  return deleteDoc(doc(db, `/courses/${course.id}`));
}
