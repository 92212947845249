import { FirebaseDateRange } from './../firebase/date-range.model';
import { DefinedDateRange } from '../components/CourseForm/course-form-model';
import { Timestamp } from 'firebase/firestore';

export function dateRangeToFirebaseRange(
  range: DefinedDateRange
): FirebaseDateRange {
  return {
    start: Timestamp.fromDate(range.from),
    end: Timestamp.fromDate(range.to),
  };
}

export function firebaseRangeToDateRange(
  firebaseRange: FirebaseDateRange
): DefinedDateRange {
  return {
    from: firebaseRange.start.toDate(),
    to: firebaseRange.end.toDate(),
  };
}
