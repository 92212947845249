import Button from '@mui/material/Button';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthContext } from '../../context/auth';
import { auth } from '../../db';

export function LoginLogoutButton() {
  const { isAdmin } = useAuthContext();
  const navigate = useNavigate();
  const [searchParam] = useSearchParams();

  const [signInWithGoogle, _, loading] = useSignInWithGoogle(auth);
  const login = async () => {
    await signInWithGoogle();
    const courseId = searchParam.get('courseId');
    if (courseId) {
      navigate(`/?courseId=${courseId}`);
    } else {
      navigate(`/courses`);
    }
  };
  const logout = () => {
    auth.signOut();
  };

  if (loading) {
    return <></>;
  } else if (isAdmin) {
    return (
      <Button color="primary" type="button" onClick={logout}>
        Odhlásit
      </Button>
    );
  } else {
    return (
      <Button color="primary" type="button" onClick={login}>
        Přihlásit
      </Button>
    );
  }

}
