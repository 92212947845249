import React, { useState, useContext } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FileCopy } from '@mui/icons-material';
import Link from '@mui/material/Link';
import { Save } from '@mui/icons-material';

import styles from './CourseHeader.module.scss';
import {
  Tooltip,
  Typography,
  IconButton,
  TextField,
  Card,
  CardContent
} from '@mui/material';
import { Course } from '../../firebase/course.model';
import { AuthContext } from '../../context/auth';
import { AttendanceFormOptions } from '../AttendanceFormOptions/AttendanceFormOptions';

interface Props {
  course: Course;
  onUpdateCourse?: (course: Partial<Course>) => void;
}

export const CourseHeader = ({ course, onUpdateCourse }: Props) => {
  const [copyTooltipVisible, setCopyTooltipVisible] = useState(false);
  const { id } = course;
  const [name, setName] = useState(course.name);
  const [location, setLocation] = useState(course.location);
  const { isAdmin } = useContext(AuthContext);
  const domain = `${window.location.protocol}//${window.location.host}`;
  const link = `${domain}/?courseId=${id}`;

  const handleAddAttendanceOption = (option: string) => {
    onUpdateCourse && onUpdateCourse({
      attendanceFormOptions: [...(course.attendanceFormOptions || []), option]
    });
  }

  const handleRemoveAttendanceOption = (option: string) => {
    onUpdateCourse && onUpdateCourse({
      attendanceFormOptions: course.attendanceFormOptions.filter(item => item !== option)
    });
  }

  return (
    <div className={styles.container}>
      <Card>
        <CardContent>
          {isAdmin ? (
            <div className={styles['form-field']}>
              <TextField
                className={styles.name}
                label="Název"
                value={name}
                margin="normal"
                variant="outlined"
                onChange={event => setName(event.target.value)}
              />
              <IconButton disabled={name === course.name} color="primary" aria-label="Uložit" onClick={() => onUpdateCourse && onUpdateCourse({ name })} >
                <Save />
              </IconButton>
            </div>
          ) : (
            <Typography variant="h3" component="h1">
              {name}
            </Typography>
          )}
          <div className={styles.location}>
            {isAdmin ? (
              <div className={styles['form-field']}>
                <TextField
                  className={styles.location}
                  label="Lokalita"
                  value={location}
                  margin="normal"
                  variant="outlined"
                  onChange={event => setLocation(event.target.value)}
                />
                <IconButton disabled={location === course.location} color="primary" aria-label="Uložit" onClick={() => onUpdateCourse && onUpdateCourse({ location })}>
                  <Save />
                </IconButton>
              </div>
            ) : (
              <Typography variant="body1">Lokalita: {location}</Typography>
            )}
          </div>
        </CardContent>
      </Card>
      {
        isAdmin && (
          <div className={styles['attendance-form']}>
            <AttendanceFormOptions options={course.attendanceFormOptions} onAddedItem={handleAddAttendanceOption} onRemoveItem={handleRemoveAttendanceOption} />
          </div>
        )
      }
      {
        isAdmin && (
          <Card >
            <CardContent>
              <div className={styles.link}>
                <Link href={link}>{link}</Link>
                <CopyToClipboard
                  text={link}
                  onCopy={() => setCopyTooltipVisible(true)}
                >
                  <Tooltip
                    title="Zkopírováno"
                    open={copyTooltipVisible}
                    onClose={() => setCopyTooltipVisible(false)}
                  >
                    <IconButton aria-label="Copy">
                      <FileCopy
                        style={{ width: '1rem', height: '1rem' }}
                        className={styles.copy}
                      />
                    </IconButton>
                  </Tooltip>
                </CopyToClipboard>
              </div>
            </CardContent>
          </Card>
        )
      }
    </div >
  );
};
