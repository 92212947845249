import React from 'react';
import { FieldArray, FieldProps } from 'formik';
import { CourseFormModel } from './course-form-model';
import Button from '@mui/material/Button';
import { DateRangeFormatter } from '../DateRangeFormatter/DateRangeFormatter';
import { DateRangePicker } from '../DateRangePicker/DateRangePicker';
import { Delete } from '@mui/icons-material';

import styles from './AvailableDates.module.scss';
import { DateRange } from 'react-day-picker';

export function AvailableDates({ form, field }: FieldProps<CourseFormModel>) {
  return (
    <FieldArray
      name={field.name}
      render={arrayHelpers => (
        <div className={styles.container}>
          <div>
            <DateRangePicker onChange={range => arrayHelpers.push(range)} />
          </div>

          <div className="list">
            {form.values.availableDates.length > 0 ? (
              form.values.availableDates.map((range: DateRange, index: number) => (
                <div key={index} className={styles.item}>
                  <DateRangeFormatter range={range} />
                  <Button
                    color="primary"
                    type="button"
                    className={styles['delete-button']}
                    onClick={() => arrayHelpers.remove(index)}
                  >
                    <Delete className={styles['delete-icon']} />
                  </Button>
                </div>
              ))
            ) : (
              <span className={styles.empty}>Nejsou zadány žádné termíny.</span>
            )}
          </div>
        </div>
      )}
    />
  );
}
