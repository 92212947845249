import React, { useState } from 'react';
import { Add } from '@mui/icons-material';
import { DefinedDateRange } from '../CourseForm/course-form-model';
import { Fab } from '@mui/material';
import { PickDateRangeDialog } from '../PickDateRangeDialog';

type Props = {
  onAddDateRange: (range: DefinedDateRange) => void;
};

export function AddDateRange({ onAddDateRange }: Props) {
  const [dialogOpen, setDialogOpen] = useState(false);

  function handleCloseDialog(range?: DefinedDateRange) {
    setDialogOpen(false);
    if (range) {
      onAddDateRange(range);
    }
  }

  return (
    <>
      <Fab
        size="small"
        color="primary"
        aria-label="Přidat termín"
        onClick={() => setDialogOpen(true)}
      >
        <Add />
      </Fab>
      <PickDateRangeDialog open={dialogOpen} onClose={handleCloseDialog} />
    </>
  );
}
