import React, { useContext } from 'react';
import { LoginLogoutButton } from '../LoginLogoutButton/LoginLogoutButton';
import { Link } from 'react-router-dom';
import { ReactComponent as Logo } from './ictpro-logo.svg';
import { Button, Menu, MenuItem } from '@mui/material';

import styles from './Header.module.scss';
import { AuthContext } from '../../context/auth';

export const Header = () => {
  const { isAdmin } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
      <div className={styles.menu}>
        {isAdmin && (
          <>
            <LoginLogoutButton />
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              Menu
            </Button>
          </>
        )}
      </div>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose} component={Link} to="/courses">
          Seznam kurzů
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/new-course">
          Nový kurz
        </MenuItem>
      </Menu>
    </div>
  );
};
