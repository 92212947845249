import { object, string, array, date, AnySchema } from 'yup';
import { AttendanceOption } from '../../firebase/course.model';

export type DefinedDateRange = {
  from: Date;
  to: Date;
};

export type CourseFormModel = {
  name: string;
  location: string;
  availableDates: DefinedDateRange[];
  attendanceFormOptions: AttendanceOption[];
  students: string[];
};

export const CourseFormSchema = object<
  Record<keyof CourseFormModel, AnySchema>
>({
  name: string().required('Povinné'),
  location: string().required('Povinné'),
  attendanceFormOptions: array(),
  availableDates: array()
    .ensure()
    .of(
      object().shape({
        from: date(),
        to: date(),
      })
    ),
  students: array().ensure().of(string()),
});
