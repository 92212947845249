import React, { useState } from 'react';
import { useCourses } from '../hooks/use-courses';
import { Link } from 'react-router-dom';
import Container from '@mui/material/Container';
import { DatesList } from '../components/DatesList';
import { Delete } from '@mui/icons-material';
import { ConfirmDialog } from '../components/ConfirmDialog';

import styles from './Courses.module.scss';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@mui/material';
import { Course } from '../firebase/course.model';
import { deleteCourse } from '../firebase/courses';

export function Courses() {
  const [courses] = useCourses();
  const [deletedCourse, setDeletedCourse] = useState<Course | null>(null);

  function handleDeleteCourse(course: Course) {
    setDeletedCourse(course);
  }

  function handleConfirmationDialogResult(result: boolean) {
    if (result && deletedCourse) {
      deleteCourse(deletedCourse);
    }
    setDeletedCourse(null);
  }

  return (
    <Container maxWidth="sm" className={styles.container}>
      <ConfirmDialog
        open={!!deletedCourse}
        title="Smazat?"
        question="Opravdu smazat kurz?"
        onClose={handleConfirmationDialogResult}
      />
      <section>
        <Typography variant="h3" component="h1">
          Vypsané kurzy
        </Typography>
        {courses && courses.length ? (
          <List>
            {courses.map((course, index) => (
              <ListItem
                key={index}
                component={Link}
                className={styles['course-item']}
                to={{ pathname: '/', search: `courseId=${course.id}` }}
              >
                <ListItemText
                  primary={course.name || 'bez názvu'}
                  secondary={
                    <DatesList
                      dates={course.availableDates.map(course =>
                        course.start.toDate()
                      )}
                      count={2}
                    />
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="Delete"
                    onClick={() => handleDeleteCourse(course)}
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography variant="body1">
            Nejsou vypsané žádné kurzy.
          </Typography>
        )}
      </section>
    </Container>
  );
}
