import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardContent, Typography, TextField, Button, Box, Paper, Grid, styled } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import { KeyboardEvent } from 'react';

type Props = {
  options: string[];
  onAddedItem: (item: string) => void;
  onRemoveItem: (item: string) => void;
}

export const AttendanceFormOptions = ({ options, onAddedItem, onRemoveItem }: Props) => {
  const [optionName, setOptionName] = useState('');

  const handleAddItem = () => {
    onAddedItem(optionName);
    setOptionName('');
  }

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddItem();
      event.stopPropagation();
    }
  }

  const handleRemoveItem = (option: string) => {
    onRemoveItem(option);
  }

  return <>
    <Card>
      <CardContent>
        <Box sx={{ mb: 1 }}>
          <Typography variant="subtitle1">
            Forma je nepovinná. Každý student ji pak může vybrat ze zde nastavených hodnot. Např. prezenční, online apod.
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <TextField label="Forma" variant="outlined" value={optionName} onChange={(e) => setOptionName(e.target.value)} onKeyDown={handleKeyDown} />
          <Button disabled={!optionName || options?.includes(optionName)} color="primary" aria-label="Uložit" onClick={() => handleAddItem()}>
            Přidat
          </Button>
        </Box>

      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          {options?.map((option, index) =>
            <Grid item xs={2} key={index}>
              <Item variant="outlined">
                {option}
                <IconButton color="primary" aria-label="Odstranit" onClick={() => handleRemoveItem(option)}>
                  <DeleteIcon />
                </IconButton>
              </Item>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>

  </>;
}

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 60,
  lineHeight: '60px',
}));