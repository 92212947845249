import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@mui/material';

type Props = {
  open: boolean;
  question: string;
  title: string;
  yes?: string;
  no?: string;
  onClose: (result: boolean) => void;
};

export const ConfirmDialog: React.FC<Props> = ({
  open,
  question,
  title,
  onClose,
  yes = 'Ano',
  no = 'Ne'
}) => {
  function handleConfirm() {
    onClose(true);
  }

  function handleCancel() {
    onClose(false);
  }

  return (
    <Dialog
      onClose={handleCancel}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{question}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {no}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {yes}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
