import { DateRange } from 'react-day-picker';
import { DateFormatter } from '../DateFormatter';

type Props = {
  range: DateRange;
};

export function DateRangeFormatter({ range }: Props) {
  return (
    <>
      {range.from?.getDate() === range.to?.getDate() ? (
        range.from && <DateFormatter date={range.from} />
      ) : (
        <>
          {range.from && <DateFormatter date={range.from} />}
          &nbsp;-&nbsp;
          {range.to && <DateFormatter date={range.to} />}
        </>
      )}
    </>
  );
}
