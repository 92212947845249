import React from 'react';

const formatter = new Intl.DateTimeFormat('cs-CZ', { 
  year: 'numeric', 
  month: 'long', 
  day: 'numeric' 
});

interface Props {
  date: Date;
}

export const DateFormatter = ({date}: Props) => <>{formatter.format(date).replace(/ /g, ' ')}</>