import { Field, FieldArray, FieldProps } from 'formik';
import { CourseFormModel } from './course-form-model';
import Button from '@mui/material/Button';
import { Delete } from '@mui/icons-material';
import { Box, TextField } from '@mui/material';
import { Student } from '../../firebase/student.model';

export function StudentsList({ form, field }: FieldProps<CourseFormModel>) {
  return (
    <FieldArray
      name={field.name}
      render={arrayHelpers => (
        <>
          {form.values.students &&
            form.values.students.map((student: Student, index: number) => (
              <Box display="flex" alignContent="center" key={index}>
                <Field
                  type="text"
                  name={`${field.name}.${index}`}
                  render={({ field }: FieldProps) => (
                    <TextField
                      margin="normal"
                      label="Jméno"
                      inputProps={{ 'aria-label': 'bare' }}
                      {...field}
                    />
                  )}
                />
                <Button
                  color="primary"
                  type="button"
                  onClick={() => arrayHelpers.remove(index)}
                >
                  <Delete />
                </Button>
              </Box>
            ))}
          <Button
            color="primary"
            type="button"
            onClick={() => arrayHelpers.push('')}
          >
            Přidat dalšího studenta
          </Button>
        </>
      )}
    />
  );
}
