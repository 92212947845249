import { Course } from './../firebase/course.model';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { db } from '../db';
import { courseConverter } from '../firebase/course.converter';
import { collection } from 'firebase/firestore';

export function useCourses() {
  return useCollectionData<Course>(
    collection(db, `/courses`).withConverter(courseConverter)
  );
}
