import { Timestamp } from 'firebase/firestore';
import { DefinedDateRange } from '../components/CourseForm/course-form-model';

import { FirebaseDateRange } from '../firebase/date-range.model';

export function isSame(date: Date, firebaseTimestamp: Timestamp) {
  return firebaseTimestamp.toDate().getTime() === date.getTime();
}

export function isSameRange(
  dateRange: DefinedDateRange,
  firebaseRange: FirebaseDateRange
) {
  return (
    isSame(dateRange.from, firebaseRange.start) &&
    isSame(dateRange.to, firebaseRange.end)
  );
}
