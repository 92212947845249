import React from 'react';
import { CourseDetail } from '../components/CourseDetail/CourseDetail';
import { Typography, Box } from '@mui/material';
import styles from './Courses.module.scss';

export function CourseDetailPage() {
  const urlParams = new URLSearchParams(window.location.search);
  const courseId = urlParams.get('courseId');

  if (courseId) {
    return <div className={styles.container}><CourseDetail courseId={courseId} /></div>
  } else {
    return (
      <Box display="flex" justifyContent="center">
        <Typography variant="h3" component="h1">
          Chybný odkaz
        </Typography>
      </Box>
    );
  }
}
