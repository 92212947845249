import { CourseForm } from '../components/CourseForm/CourseForm';
import { useNavigate } from 'react-router-dom';
import { createCourse } from '../firebase/courses';

import styles from './NewCourse.module.scss';
import { CourseFormModel } from '../components/CourseForm/course-form-model';

export function NewCourse() {

  const navigate = useNavigate();

  async function handleSubmit(model: CourseFormModel) {
    const courseId = await createCourse(model);
    navigate(`/?courseId=${courseId}`);
  }

  return (
    <div className={styles.container}>
      <CourseForm
        onSubmit={handleSubmit}
      />
    </div>
  );
}
