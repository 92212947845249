import {
  FirestoreDataConverter,
  WithFieldValue,
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from 'firebase/firestore';
import { Course } from './course.model';

export const courseConverter: FirestoreDataConverter<Course> = {
  toFirestore(data: WithFieldValue<Course>): DocumentData {
    return {
      name: data.name,
      availableDates: data.availableDates,
      location: data.location,
      attendanceFormOptions: data.attendanceFormOptions,
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Course {
    const data = snapshot.data(options)!;
    return {
      id: snapshot.id,
      name: data.name,
      availableDates: data.availableDates,
      location: data.location,
      attendanceFormOptions: data.attendanceFormOptions,
    };
  },
};
