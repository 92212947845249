import React from 'react';
import { Header } from './components/Header/Header';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { CourseDetailPage } from './containers/CourseDetailPage';
import { Courses } from './containers/Courses';
import { NewCourse } from './containers/NewCourse';
import { AuthContext, useAuthContext } from './context/auth';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';

import CssBaseline from '@mui/material/CssBaseline';
import { LoginPage } from './containers/LoginPage';
import { createTheme, ThemeProvider } from '@mui/material';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      light: '#ffee60',
      main: '#3F1C03',
      dark: '#484848',
      contrastText: '#fabc2a',
    },
    secondary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#ffffff',
    },
    background: {
      default: '#EEEEEE',
      paper: '#F5F5F6'
    }
  },
});

const App: React.FC = () => {
  const authContext = useAuthContext();
  return (
    <>
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <AuthContext.Provider value={authContext}>
          <Router>
            <Header />
            <Routes>
              <Route path="/" element={<CourseDetailPage />} />
              <Route path="/new-course" element={<ProtectedRoute><NewCourse /></ProtectedRoute>} />
              <Route path="/courses" element={<ProtectedRoute><Courses /></ProtectedRoute>} />
              <Route path="/admin" element={<LoginPage />} />
            </Routes>
          </Router>
        </AuthContext.Provider>
      </ThemeProvider>
    </>
  );
};

export default App;
