import {
  doc,
  updateDoc,
  deleteDoc,
  addDoc,
  collection,
} from 'firebase/firestore';
import { db } from '../db';
import { AttendanceOption } from './course.model';
import { FirebaseDateRange } from './date-range.model';

export function updateSelectedDates(
  courseId: string,
  studentId: string,
  selectedDates: FirebaseDateRange[]
) {
  return updateDoc(doc(db, `/courses/${courseId}/students/${studentId}`), {
    selectedDates,
  });
}
export function updateAttendanceForm(
  courseId: string,
  studentId: string,
  attendanceForm: AttendanceOption
) {
  return updateDoc(doc(db, `/courses/${courseId}/students/${studentId}`), {
    attendanceForm,
  });
}

export function deleteStudent(courseId: string, studentId: string) {
  return deleteDoc(doc(db, `/courses/${courseId}/students/${studentId}`));
}

export function addStudent(courseId: string, studentName: string) {
  return addDoc(collection(db, `/courses/${courseId}/students`), {
    name: studentName,
    selectedDates: [],
  });
}
