import { useCollectionData } from 'react-firebase-hooks/firestore';
import { collection } from 'firebase/firestore';
import { Student } from '../firebase/student.model';
import { db } from '../db';
import { studentConvertor } from '../firebase/students.converter';

export function useStudents(courseId: string) {
  return useCollectionData<Student>(
    collection(db, `/courses/${courseId}/students`).withConverter(
      studentConvertor
    )
  );
}
