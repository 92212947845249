import { User } from 'firebase/auth';
import { auth } from '../db';
import React from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

export type AuthState = {
  user: User | null | undefined;
  isAdmin: boolean;
  loading: boolean;
};

export const AuthContext = React.createContext<AuthState>({
  user: undefined,
  isAdmin: false,
  loading: true,
});

export const useAuthContext = (): AuthState => {
  const [user, loading] = useAuthState(auth);
  const isAdmin = !!(
    user &&
    user.email &&
    (user.email === 'martin.nuc@gmail.com' || user.email.includes('@ictpro.cz'))
  );

  return { user, loading, isAdmin };
};
