import React, { useState } from 'react';
import {
  InputAdornment,
  IconButton,
  InputLabel,
  Input,
  FormControl
} from '@mui/material';
import { PersonAdd } from '@mui/icons-material';

import styles from './AddStudentRow.module.scss';

type Props = {
  onAddStudent: (studentName: string) => void;
};

export const AddStudentRow: React.FC<Props> = ({ onAddStudent }) => {
  const [name, setName] = useState('');

  function handleAddStudent() {
    if (!name) {
      return;
    }
    onAddStudent(name);
    setName('');
  }

  return (
    <div className={styles['student-field']}>
      <FormControl>
        <InputLabel htmlFor="new-student">Nový student</InputLabel>
        <Input
          id="new-student"
          value={name}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setName(event.target.value)
          }
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              handleAddStudent();
              ev.preventDefault();
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                color="primary"
                aria-label="Toggle password visibility"
                onClick={handleAddStudent}
              >
                <PersonAdd />
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
    </div>
  );
};
