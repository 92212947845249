import React, { useContext } from 'react';
import { AttendanceMatrixColumn } from './AttendanceMatrixColumn';
import { Student } from '../../firebase/student.model';
import { isSameRange } from '../../utils/date-range-firebase-timestamp-comparator';
import { DefinedDateRange } from '../CourseForm/course-form-model';
import { AddStudentRow } from '../AddStudentRow';
import { AddDateRange } from '../AddDateRange';
import { FirebaseDateRange } from '../../firebase/date-range.model';
import { dateRangeToFirebaseRange } from '../../utils/date-range-firebase-timestamp-convertor';

import styles from './AttendanceMatrix.module.scss';
import { AuthContext } from '../../context/auth';
import { Chip, Avatar, FormControl, MenuItem, Select, styled } from '@mui/material';
import { Face } from '@mui/icons-material';
import { AttendanceOption } from '../../firebase/course.model';

const WhiteChip = styled(Chip)`
  background: white;
`;

interface Props {
  availableDates: DefinedDateRange[];
  attendanceFormOptions: AttendanceOption[];
  students: Student[];
  updateSelectedDates?: (
    studentId: string,
    selectedDates: FirebaseDateRange[]
  ) => void;
  updateAttendanceForm?: (
    studentId: string,
    selectedDates: AttendanceOption
  ) => void;
  onDeleteStudent?: (student: Student) => void;
  onAddStudent?: (studentName: string) => void;
  onAddDateRange?: (range: DefinedDateRange) => void;
  onDeleteDateRange?: (range: DefinedDateRange) => void;
}

function sumAttendance({
  availableDates,
  students
}: {
  availableDates: DefinedDateRange[];
  students: Student[];
}) {
  return availableDates.map(availableDate => {
    const dates = students
      .map(student => student.selectedDates)
      .reduce((acc, curr) => [...acc, ...curr], []);
    return dates.reduce(
      (acc, curr) => (isSameRange(availableDate, curr) ? acc + 1 : acc),
      0
    );
  });
}

export const AttendanceMatrix = ({
  availableDates,
  attendanceFormOptions,
  students,
  updateAttendanceForm,
  updateSelectedDates,
  onAddStudent,
  onAddDateRange,
  onDeleteStudent,
  onDeleteDateRange
}: Props) => {
  const { isAdmin } = useContext(AuthContext);
  const editAllowed = !!(onAddDateRange && onAddStudent && isAdmin);
  document.documentElement.style.setProperty(
    '--students-count',
    `${students.length}`
  );
  document.documentElement.style.setProperty(
    '--dates-count',
    `${availableDates.length}`
  );
  document.documentElement.style.setProperty(
    '--number-of-attendance-form-columns',
    `${attendanceFormOptions?.length ? 1 : 0}`
  );

  const sums = sumAttendance({ availableDates, students });
  const winningSum = sums.reduce(
    (acc: number, curr: number) => Math.max(acc, curr),
    0
  );
  const winningIndexes = winningSum
    ? sums.reduce(
      (acc, curr, index) => (curr === winningSum ? [...acc, index] : acc),
      [] as number[]
    )
    : [];

  return (
    <div className={styles['scrolling-wrapper']}>
      <div className={styles.table}>
        <div className={styles.student}>&nbsp;</div>
        {students.map((student, index) => (
          <div key={index} className={styles.student}>
            <WhiteChip
              avatar={
                <Avatar>
                  <Face />
                </Avatar>
              }
              label={student.name}
              onDelete={
                isAdmin && onDeleteStudent
                  ? () => onDeleteStudent(student)
                  : undefined
              }
              variant="outlined"
            />
          </div>
        ))}
        {editAllowed && onAddStudent ? (
          <div className={styles.student}><AddStudentRow onAddStudent={onAddStudent} /></div>
        ) : (
          <div className={styles.student} />
        )}

        {attendanceFormOptions?.length > 0 && (<>
          <div className={styles['attendance-form']}>Forma</div>
          {students.map((student, index) => (
            <div key={index} className={styles['attendance-form']}>
              <FormControl fullWidth>
                <Select
                  value={student.attendanceForm}
                  label="Forma"
                  variant="standard"
                  placeholder="Vyberte"
                  displayEmpty={true}
                  renderValue={value => value?.length ? value : 'Vyberte'}
                  onChange={(e) => updateAttedanceForm(student.id, e.target.value as string)}
                >
                  {attendanceFormOptions.map((attendanceOption, index) => (
                    <MenuItem key={index} value={attendanceOption}>{attendanceOption}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ))}
          <div className={styles['attendance-form']} />
        </>)}

        {availableDates.map((range, index) => (
          <AttendanceMatrixColumn
            key={index}
            range={range}
            students={students}
            total={calculateTotal(students, range)}
            winning={winningIndexes.includes(index)}
            onChange={(student, newRange) =>
              update(student.id, student.selectedDates, newRange)
            }
            onDeleteDateRange={
              editAllowed && onDeleteDateRange
                ? deletedRange =>
                  onDeleteDateRange && onDeleteDateRange(deletedRange)
                : undefined
            }
          />
        ))}
        <div className={styles['add-new-range']}>
          {editAllowed && onAddDateRange && (
            <AddDateRange onAddDateRange={onAddDateRange} />
          )}
        </div>
      </div>
    </div>
  );

  function calculateTotal(students: Student[], range: DefinedDateRange) {
    return students.reduce(
      (acc, curr) =>
        curr.selectedDates.some(date => isSameRange(range, date))
          ? acc + 1
          : acc,
      0
    );
  }

  function updateAttedanceForm(studentId: string, attendanceForm: string) {
    updateAttendanceForm?.(studentId, attendanceForm);
  }

  function update(
    studentId: string,
    oldSelectedDates: FirebaseDateRange[],
    changedDate: DefinedDateRange
  ) {
    if (!updateSelectedDates) {
      return;
    }
    const filtered = oldSelectedDates.filter(
      selectedDate => !isSameRange(changedDate, selectedDate)
    );
    if (filtered.length === oldSelectedDates.length) {
      updateSelectedDates(studentId, [
        ...oldSelectedDates,
        dateRangeToFirebaseRange(changedDate)
      ]);
    } else {
      updateSelectedDates(studentId, filtered);
    }
  }
};
