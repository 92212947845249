import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage, FormikProps } from 'formik';
import { StudentsList } from './StudentsList';
import { CourseFormModel, CourseFormSchema } from './course-form-model';
import { AvailableDates } from './AvailableDates';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { AttendanceMatrix } from '../AttendanceMatrix';
import {
  Stepper,
  Step,
  StepLabel,
  StepContent
} from '@mui/material';

import styles from './CourseForm.module.scss';

const initialValues: CourseFormModel = {
  name: '',
  location: '',
  availableDates: [],
  attendanceFormOptions: [],
  students: ['']
};

type Props = {
  onSubmit: (values: CourseFormModel) => void;
};

const buttonStyle = {
  marginTop: 1,
  marginRight: 1
}

type StepProps = Pick<
  FormikProps<CourseFormModel>,
  | 'values'
  | 'handleChange'
  | 'handleBlur'
  | 'errors'
  | 'touched'
  | 'isSubmitting'
  | 'setFieldTouched'
>;

export function CourseForm({ onSubmit }: Props) {
  const [activeStep, setActiveStep] = useState<number>(0);
  const steps = ['Název kurzu a lokalita', 'Termíny', 'Studenti'];

  function handleNext() {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep((prevActiveStep: number) => prevActiveStep - 1);
  }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={CourseFormSchema}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleBlur,
          errors,
          touched,
          setFieldTouched
        }) => (
          <>
            <Form>
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>
                      {getStepContent(index)({
                        values,
                        handleChange,
                        handleBlur,
                        errors,
                        touched,
                        isSubmitting,
                        setFieldTouched
                      })}
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
            </Form>

            <div className={styles.preview}>
              {values.availableDates.length && values.students.length ? (
                <AttendanceMatrix
                  availableDates={values.availableDates}
                  attendanceFormOptions={values.attendanceFormOptions}
                  students={values.students.map(studentName => ({
                    id: '',
                    name: studentName,
                    selectedDates: []
                  }))}
                />
              ) : (
                <div>Zde bude vidět náhled zobrazený pro studenty.</div>
              )}
            </div>
          </>
        )}
      </Formik>
    </div>
  );

  function getStepContent(step: number): (props: StepProps) => JSX.Element {
    switch (step) {
      case 0:
        return ({
          errors,
          handleBlur,
          handleChange,
          values,
          touched,
          setFieldTouched
        }: StepProps) => (
          <>
            <div>
              <Field
                error={errors.name && touched.name}
                id="name"
                name="name"
                label="Název"
                margin="normal"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                helperText={errors.name && touched.name && errors.name}
                component={TextField}
              />
            </div>
            <div>
              <Field
                error={errors.location && touched.location}
                id="location"
                name="location"
                label="Lokalita"
                margin="normal"
                variant="outlined"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.location}
                helperText={
                  errors.location && touched.location && errors.location
                }
                component={TextField}
                onInput={() => setFieldTouched('location', true, true)}
              />
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                sx={buttonStyle}
                disabled={
                  !!(
                    !touched.name ||
                    !touched.location ||
                    errors.name ||
                    errors.location
                  )
                }
              >
                Další
              </Button>
            </div>
          </>
        );
      case 1:
        return () => (
          <>
            <div>
              <Field name="availableDates" component={AvailableDates} />
              <ErrorMessage
                name="availableDates"
                className="error"
                component="div"
              />
            </div>
            <div>
              <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={buttonStyle}
              >
                Zpět
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
                sx={buttonStyle}
              >
                Další
              </Button>
            </div>
          </>
        );
      case 2:
        return ({ values }) => (
          <>
            <div>
              <Field name="students" component={StudentsList} />
              <ErrorMessage name="students" className="error" component="div" />
              <div>
                <Button
                  variant="contained"
                  onClick={() => onSubmit(values)}
                  sx={buttonStyle}
                >
                  Dokončit
                </Button>
              </div>
            </div>
          </>
        );
      default:
        return () => <span>Neznámý krok</span>;
    }
  }
}
