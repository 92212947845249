import React from 'react';
import { DateFormatter } from '../DateFormatter';

type Props = {
  dates: Date[],
  count: number;
}

export function DatesList({dates, count = 3}: Props) {
  return (
    <>
      {dates.reduce(
        (acc, curr, index, array) => {
          if (index < count) {
            const item = (
              <span key={index}>
                {index !== 0 && <>, </>}
                <DateFormatter date={curr} />
                {index === count - 1 && array.length > count && <>...</>}
              </span>
            );

            return [...acc, item];
          } else {
            return acc;
          }
        },
        [] as JSX.Element[]
      )}
    </>
  );
}
