import React, { useContext } from 'react';
import { AttendanceMatrix } from '../../components/AttendanceMatrix/AttendanceMatrix';
import { CourseHeader } from '../../components/CourseHeader/CourseHeader';
import { useCourse } from '../../hooks/use-course';
import { useStudents } from '../../hooks/use-students';
import {
  updateSelectedDates,
  updateAttendanceForm,
  deleteStudent,
  addStudent
} from '../../firebase/students';
import { firebaseRangeToDateRange } from '../../utils/date-range-firebase-timestamp-convertor';
import {
  addDateRange,
  deleteDateRange,
  updateCourse
} from '../../firebase/courses';
import { Card, CardContent, Container, Typography } from '@mui/material';
import { AuthContext } from '../../context/auth';

type Props = {
  courseId: string;
};

export function CourseDetail({ courseId }: Props) {
  const [course, loadingCourse, errorCourse] = useCourse(courseId);
  const [students, loadingStudents, errorStudents] = useStudents(courseId);
  const { isAdmin } = useContext(AuthContext);

  if (loadingCourse || loadingStudents) {
    return null;
  }

  if (errorCourse || errorStudents) {
    return <Typography variant="body1" component="p">
      Stránka používá websockety, které jsou blokovány nastavením vašeho zabezpečení. Kontaktujte ICT Pro.
    </Typography>
  }

  return (
    <>
      {course && students ? (
        <>
          <CourseHeader
            course={course}
            onUpdateCourse={edittedCourse =>
              updateCourse(course.id, edittedCourse)
            }
          />
          <Card>
            <CardContent>
              {!isAdmin && <Typography variant="body1" component="p">
                Pro naplánování termínu školení prosím o zatržení vyhovujících termínů na řádku u vašeho jména. Čím více možností dáte, tím lépe pro nalezení potřebné shody. Zatržené hodnoty se po kliknutí automaticky ukládají bez nutnosti potvrzení.
              </Typography>}

              <AttendanceMatrix
                availableDates={course.availableDates.map(date =>
                  firebaseRangeToDateRange(date)
                )}
                attendanceFormOptions={course.attendanceFormOptions}
                students={students}
                updateAttendanceForm={(studentId, attendanceForm) =>
                  updateAttendanceForm(courseId, studentId, attendanceForm)
                }
                updateSelectedDates={(studentId, selectedDates) =>
                  updateSelectedDates(courseId, studentId, selectedDates)
                }
                onDeleteStudent={student => deleteStudent(courseId, student.id)}
                onAddStudent={studentName => addStudent(courseId, studentName)}
                onAddDateRange={range => addDateRange(course, range)}
                onDeleteDateRange={range => deleteDateRange(course, range)}
              />

            </CardContent>
          </Card>
        </>
      ) : (
        <Container maxWidth="sm">
          <Typography variant="h3" component="h1">
            Kurz nenalezen
          </Typography>
        </Container>
      )}
    </>
  );
}
